import React, { Fragment } from 'react';
import { addons } from '@storybook/addons';
import { componentStatus } from '../src/status';
import { StatusBadge } from '../src/components/status_badge.component';
import { create } from "@storybook/theming";
import packageJSON from '@cellula/react/package.json';

const logotype = `<div style="display: flex; justify-content: space-between; align-items: flex-end;"><img src="{logotypeUrl}" /><div style="margin-top: 0.5rem; font-size: 0.75rem; color: {versionColor};">v${packageJSON.version}</div></div>`;

addons.setConfig({
	theme: create({
    base: "light",
    brandTitle: logotype.replace('{logotypeUrl}', '/logotype-cellula-dark.svg').replace('{versionColor}', '#283C55'),
    brandUrl: null,
    brandImage: null,
  }),
	sidebar: {
		renderLabel: (item) => {
			if (item.isComponent) {
				const status = componentStatus[item.name];

				if (['deprecated', 'needs-revision', 'beta'].includes(status)) {
					return (
						<Fragment>
							{item.name}
							<StatusBadge status={status}>{status.replace('-', ' ')}</StatusBadge>
						</Fragment>
					);
				}
			}

			return item.name;
		},
	},
});
