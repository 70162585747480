import React, { ReactNode } from 'react';
import { useDarkMode } from 'storybook-dark-mode';
import styled from 'styled-components';
import { STATUS } from '../status';

const StyledStatusBadge = styled.span`
	background: #f00;
	border-radius: 4px;
	font-size: 0.625rem;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0 4px;
	margin-left: 8px;

	&[data-status='needs-revision'] {
		border: 1px solid #66460d;
		color: #66460d;
		background: #ffefd2;

		&[data-dark-mode] {
			border: 1px solid #66460d;
			color: #ffefd2;
			background: #66460d;
		}
	}

	&[data-status='deprecated'] {
		border: 1px solid #85462b;
		color: #85462b;
		background: #f8e3da;

		&[data-dark-mode] {
			border: 1px solid #85462b;
			color: #f8e3da;
			background: #85462b;
		}
	}

	&[data-status='beta'] {
		border: 1px solid #2952cc;
		color: #2952cc;
		background: #d7e0ff;

		&[data-dark-mode] {
			border: 1px solid #2952cc;
			color: #d7e0ff;
			background: #2952cc;
		}
	}
`;

interface StatusBadgeProps {
	status: keyof typeof STATUS;
	children: ReactNode;
}

export function StatusBadge({ status, children }: StatusBadgeProps): JSX.Element {
	return (
		<StyledStatusBadge data-status={status} data-dark-mode={useDarkMode() || undefined}>
			{children}
		</StyledStatusBadge>
	);
}
