export const STATUS = {
	STABLE: 'stable',
	NEEDS_REVISION: 'needs-revision',
	BETA: 'beta',
	DEPRECATED: 'deprecated',
};

export const componentStatus = {
	Avatar: STATUS.STABLE,
	BadgeNumber: STATUS.STABLE,
	BadgeText: STATUS.STABLE,
	Button: STATUS.STABLE,
	DialogAlert: STATUS.STABLE,
	DialogModal: STATUS.NEEDS_REVISION,
	Divider: STATUS.STABLE,
	FileViewer: STATUS.NEEDS_REVISION,
	Input: STATUS.STABLE,
	Label: STATUS.STABLE,
	TextArea: STATUS.STABLE,
	InputTextArea: STATUS.STABLE,
	InputText: STATUS.STABLE,
	Grid: STATUS.STABLE,
	I18nProvider: STATUS.STABLE,
	Icon: STATUS.STABLE,
	IconButton: STATUS.STABLE,
	InputCheckbox: STATUS.STABLE,
	InputRadio: STATUS.STABLE,
	InputSelect: STATUS.NEEDS_REVISION,
	Menu: STATUS.DEPRECATED,
	NotificationAlert: STATUS.STABLE,
	Provider: STATUS.STABLE,
	Select: STATUS.DEPRECATED,
	Sheet: STATUS.NEEDS_REVISION,
	Spinner: STATUS.STABLE,
	TabNavigation: STATUS.STABLE,
	Text: STATUS.STABLE,
	Tooltip: STATUS.STABLE,
	VisuallyHidden: STATUS.STABLE,
};
