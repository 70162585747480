{
	"name": "@cellula/react",
	"version": "1.2.0",
	"author": "Visiba Group AB (https://www.visibacare.com/)",
	"description": "",
	"main": "./dist/index.js",
	"module": "./dist/index.mjs",
	"types": "./dist/index.d.ts",
	"sideEffects": false,
	"files": [
		"dist/**"
	],
	"scripts": {
		"test": "cross-env vitest",
		"build": "tsup index.tsx --format esm,cjs --dts --minify --external react,react-dom,styled-components,uuid",
		"dev": "tsup index.tsx --format esm,cjs --watch --external react,react-dom,styled-components,uuid",
		"lint": "TIMING=1 eslint \"./**/*.ts*\" --fix",
		"clean": "rm -rf .turbo && rm -rf dist",
		"clean:hard": "pnpm clean && rm -rf node_modules"
	},
	"repository": {
		"type": "git",
		"url": "https://github.com/VisibaCare/cellula.git",
		"directory": "packages/cellula"
	},
	"peerDependencies": {
		"@visiba-cortex/std": "1.0.0-rc.1",
		"react": ">=16.8 || ^17.0.0 || ^18.0.0",
		"react-dom": ">=16.8 || ^17.0.0 || ^18.0.0",
		"styled-components": ">=5.3"
	},
	"dependencies": {
		"@popperjs/core": "2.11.8",
		"@radix-ui/react-alert-dialog": "1.0.5",
		"@radix-ui/react-avatar": "1.0.3",
		"@radix-ui/react-aspect-ratio": "1.0.3",
		"@radix-ui/react-accordion": "1.1.2",
		"@radix-ui/react-dialog": "1.0.5",
		"@radix-ui/react-popover": "1.0.6",
		"@radix-ui/react-portal": "1.0.3",
		"@radix-ui/react-select": "2.0.0",
		"@radix-ui/react-slot": "1.0.2",
		"@radix-ui/react-switch": "1.0.3",
		"@radix-ui/react-tabs": "1.0.4",
		"@radix-ui/react-tooltip": "1.0.6",
		"@react-aria/utils": "3.19.0",
		"cmdk": "0.2.0",
		"react-hook-form": "7.43.9",
		"uuid": "9.0.0"
	},
	"devDependencies": {
		"@types/uuid": "9.0.2",
		"@visiba-cortex/std": "1.0.0-rc.1"
	}
}
